import React from 'react';
// import { useForm } from "react-hook-form";

const JobForm = () => {
    // const { register, errors } = useForm({
    //     mode: "onBlur"
    // });
    return (
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
            {/* <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSd8of98CVMb4XMhCW_IRk5booCq8xGmGfQ68VD7aHK3owjIAw/formResponse" method="POST">
                <div className="row mb-n6">
                    <div className="col-md-6 col-12 mb-6">
                        <input type="text" placeholder="First Name *" name="entry.1357452054" />
                        {errors.Firstname && <p>{errors.Firstname.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="text" placeholder="Last Name *" name="entry.1582208489"  />
                        {errors.Lastname && <p>{errors.Lastname.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="email" placeholder="Email *" name="entry.1263752934" />
                        {errors.Email && <p>{errors.Email.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="tel" placeholder="Phone *" name="entry.1364100088"  />
                        {errors.Phone && <p>{errors.Phone.message}</p>}
                    </div>
                    <div className="col-md-12 col-12 mb-6">
                        <input type="text" placeholder="Job Title *" name="entry.1758198859"  />
                        {errors.Jobtitle && <p>{errors.Jobtitle.message}</p>}
                    </div>
                    <label>
                        Upload Resume:
                        <div className="col-md-12 col-4 mb-6">
                            <input type="file" name="File" ref={register({ required: 'Resume is required' })} />
                            {errors.File && <p>{errors.File.message}</p>}
                        </div>
                    </label>
                    <div className="col-12 mb-6">
                        <textarea name="entry.1312215406" placeholder="Tell us something about yourself" ></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                    <div className="col-12 text-center mb-6">
                        <button type="submit" className="btn btn-primary btn-hover-secondary">Submit</button>
                    </div>
                </div>
            </form> */}
        </div>
    )
}

export default JobForm;
