import { useState, useEffect, useRef } from "react";
import SectionTitle from "../SectionTitles/SectionTitle";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
// import { Link } from "react-router-dom";

const HomeAbout = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div
      id="about"
      className="section section-padding-t90 section-padding-bottom-200"
    >
      <div className="container">
        <SectionTitle
          title="We believe in the transformative power of modern technology"
          subTitle="We are a group of IT experts sharing one common thought, to provide highly capable and affordable enterprise solutions."
        />

        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/about/home-one-about/home_agency_about_1.jpg"
                    }
                    alt=""
                  />
                </Tilt>
              </div>

              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/about/home-one-about/home_agency_about_2.jpg"
                    }
                    alt=""
                  />
                </Tilt>
              </div>
              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/shape-animation/about-shape-1.png"
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle="Every day brings new challenges"
                title="Leading the Way with a Skilled and Experienced Team"
              />

              <p>
                Our efforts focus on Web Development, Mobile Development,
                Digital Product Design to IT Consulting and MVP Solutions. Every
                client we work with becomes a part of the team and together we
                face challenges and celebrate our victory. We aim at providing
                high-capability software solutions at a cost-effective price.
              </p>

              {/* <Link
                className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4"
                to={process.env.PUBLIC_URL + "/about"}
              >
                Contact Us
              </Link> */}
              <a href={`#services`}>
                <span className="btn btn-2 btn-custom mt-4">Services</span>
              </a>
              <a href={`#contact`}>
                <span className="btn btn-2 btn-custom-outline mt-4 ml-2">
                  Contact Us
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
