import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const BlogDetails = ({ data }) => {
    return (
        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="info">
                <h3 className="title">{data.title}</h3>
                {data.body.map((value, i) => {
                    return(
                        <div key={i} className="content" dangerouslySetInnerHTML={{__html: value}} />
                    )
                })}
                <ul className="meta mb-0 mt-12">
                    <li><i className="far fa-calendar"></i>{data.date}</li>
                    <li className="media"><Link to={process.env.PUBLIC_URL + "/"}><i className="fas fa-share-alt"></i>Share this post</Link>
                        <div className="list">
                            <a href="https://www.facebook.com/sharer.php?u=https://exomac.jamstacktemplates.dev/blog-details/1" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://twitter.com/intent/tweet?url=https://exomac.jamstacktemplates.dev/blog-details/1" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/shareArticle?url=https://exomac.jamstacktemplates.dev/blog-details/1" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                        </div>
                    </li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScfpYByhkNpHJtjpPvTYgtpSYR708zTTsSYszv0Sh2WuFhxVQ/viewform?usp=sf_link" target="blank" className="btn btn-custom ml-5">Apply</a>
                </ul>
            </div>
        </div>
    )
}

BlogDetails.propTypes = {
    data: PropTypes.object
};

export default BlogDetails
