import React from "react";
// import {Link} from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";
import ReactWhatsapp from "react-whatsapp";

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
                />{" "}
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                    <a href="tel:+918606762286"> +91 8606 762 286 </a>{" "}
                  </p>{" "}
                  <p>
                    <a href="tel:+919539258025"> +91 9539 258 025 </a>{" "}
                  </p>{" "}
                  <p>
                    <a href="mailto:hr@appsiologix.com">
                      {" "}
                      hr@appsiologix.com{" "}
                    </a>{" "}
                  </p>{" "}
                </div>{" "}
                {/* <div className="footer-social-inline">
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter-square"> </i>{" "}
                  </a>{" "}
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-square"> </i>{" "}
                  </a>{" "}
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"> </i>{" "}
                  </a>
                </div> */}
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignSelf: "end",
                }}
              >
                <ReactWhatsapp
                  number="+91 9495114633"
                  message=""
                  style={{ backgroundColor: "#030e22", border: "none" }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "images/icons/whatsapp.png"}
                    alt="whatsapp icon"
                    style={{ backgroundColor: "#030e22" }}
                  />
                </ReactWhatsapp>
              </div> */}
            </div>
          </div>
          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6"
                >
                  {single.text !== "Live Chat" && (
                    <FooterLinkItem data={single} key={key} />
                  )}
                </div>
              );
            })}
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div className="footer-social-inline">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter-square"> </i>{" "}
            </a>{" "}
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square"> </i>{" "}
            </a>{" "}
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"> </i>{" "}
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <ReactWhatsapp
              number="+91 95392 58025"
              message=""
              style={{ backgroundColor: "#030e22", border: "none" }}
            >
              <img
                src={process.env.PUBLIC_URL + "images/icons/whatsapp.png"}
                alt="whatsapp icon"
                style={{ backgroundColor: "#030e22" }}
              />
            </ReactWhatsapp>
          </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <ReactWhatsapp
              number="+91 9495114633"
              message=""
              style={{ backgroundColor: "#030e22", border: "none" }}
            >
              <img
                src={process.env.PUBLIC_URL + "images/icons/whatsapp.png"}
                alt="whatsapp icon"
                style={{ backgroundColor: "#030e22" }}
              />
            </ReactWhatsapp>
          </div> */}
        </div>
        <div className="row">
          <div className="col">
            <p className="copyright">
              ©️ 2022
              <strong> Appsiologix Business Solutions Pvt Ltd </strong>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
