import PropTypes from "prop-types";
import React from 'react';
import BlogDetails from '../../components/Blog/BlogDetails.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarTitle from '../../components/Sidebar/SidebarTitle';
import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';

const BlogDetailsContainer = ({data}) => {
    return (
        <div className="section section-padding fix">
            <div className="container">
                <div className="row mb-n10">

                    <div className="col-lg-8 col-12 order-lg-1 mb-10">
                        <div className="row row-cols-1 no-gutters">
                            
                            <BlogDetails data={data} />

                        </div>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 mb-10">
                        <SidebarWrap>
                            <SidebarWidget>
                                <SidebarTitle classOption="mb-2 mt-10" title="Related Jobs" />
                                <SidebarPost />
                            </SidebarWidget>
                        </SidebarWrap>
                    </div>

                </div>
            </div>
        </div>
    )
}
BlogDetailsContainer.propTypes = {
    data: PropTypes.object
};

export default BlogDetailsContainer;
