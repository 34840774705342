import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const BlogItem = ({ data }) => {
  return (
    <div className="blog">
      <div className="thumbnail">
        <Link
          to={process.env.PUBLIC_URL + `/career/${data.id}`}
          className="image"
        >
          <img src={process.env.PUBLIC_URL + data.image} alt="Blog Image" />
        </Link>
      </div>
      <div className="info">
        <ul className="meta">
          <li>
            <i className="far fa-calendar"></i>
            {data.date}
          </li>
          {/* <li><i className="far fa-eye"></i>{data.view}</li> */}
        </ul>
        <h3 className="title">
          <Link to={process.env.PUBLIC_URL + `/career/${data.id}`}>
            {data.title}
          </Link>
        </h3>
        <p className="title" style={{ textAlign: "justify", fontSize: 18 }}>
          <Link
            to={process.env.PUBLIC_URL + `/career/${data.id}`}
          >{`${data.excerpt.slice(0, 80)}...`}</Link>
        </p>
        <Link
          to={process.env.PUBLIC_URL + `/career/${data.id}`}
          className="link"
        >
          <mark>Read More</mark>
        </Link>
        {/* <Link
          className="btn btn-custom ml-5"
          to={process.env.PUBLIC_URL + `https://docs.google.com/forms/d/e/1FAIpQLScfpYByhkNpHJtjpPvTYgtpSYR708zTTsSYszv0Sh2WuFhxVQ/viewform?usp=sf_link`}
        >
          Apply
        </Link> */}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScfpYByhkNpHJtjpPvTYgtpSYR708zTTsSYszv0Sh2WuFhxVQ/viewform?usp=sf_link" target="blank" className="btn btn-custom ml-5">Apply</a>
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  data: PropTypes.object,
};

export default BlogItem;