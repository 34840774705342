import React from 'react';
import PropTypes from "prop-types";
import BlogClassicData from '../../data/blog/BlogClassic.json';
import BlogItem from '../../components/Blog/BlogItem';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import { Link } from "react-router-dom";


const HomeBlog = ({ SectionBgColor }) => {
    return (
        <div id="careers" className={`section section-padding-t90-b100 ${SectionBgColor}`}>
            <div className="container">
                <SectionTitle
                    title="Join the journey with us !"
                    subTitle="Join us and explore the world of technology as you have never seen before"
                />
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                    {BlogClassicData && BlogClassicData.slice(0, 3).map((single, key) => {
                        return (
                            <div key={key} className="col mb-6" data-aos="fade-up">
                                <BlogItem data={single} key={key} />
                            </div>
                        );
                    })}
                </div>
                <Link
                    to={process.env.PUBLIC_URL + "/careers"}
                    className="link "
                    style={{display: "flex", justifyContent: "center"}}
                >
                    <mark>See More</mark>
                </Link>
            </div>
            {/* <div style={{ alignSelf: "center", marginLeft: "43%" }}>
                <Link
                    to={process.env.PUBLIC_URL + "/careers"}
                    className="link ml-10"
                >
                    <mark>See More</mark>
                </Link>
            </div> */}
        </div>
    )
}
HomeBlog.propTypes = {
    SectionBgColor: PropTypes.string
};
HomeBlog.defaultProps = {
    SectionBgColor: "section section-padding-t90-b100"
};

export default HomeBlog
