import React from 'react';
import SEO from '../components/SEO';
// import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
// import GoogleMap from '../container/Map/GoogleMap';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
// import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const Contact = () => {
    return (
        <React.Fragment>
            <SEO title="Appsiologix Business Solutions" />
            {/* <Breadcrumb 
                image="images/bg/breadcrumb-bg-two.jpg"
                title="Get in touch with us"
            /> */}
            <ContactFromContainer />
            <ContactInformation />
            {/* <GoogleMap /> */}
            {/* <Footer /> */}
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Contact;

