import React from 'react';
import PropTypes from "prop-types"
import Logo from '../../components/logo/Logo';
// import MobileNavMenu from './sub-component/MobileNavMenu';
import {NavLink} from 'react-router-dom';

const MobileMenu = ({show, onClose}) => {
    return (
        <div id="site-main-mobile-menu" className={`site-main-mobile-menu ${show ? "active": ""}`}>
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <Logo image={process.env.PUBLIC_URL + "/images/logo/logo.png"}/>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle" onClick={onClose}>
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
            <nav className="site-mobile-menu">
            <ul>
                <li className="has-children">
                <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text" onClick={onClose}>Home</span></NavLink>
                    {/* <span className="menu-toggle" onClick={onClickHandler}><i className="icon far fa-angle-down"></i></span> */}
                    {/* <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-one"}><span className="menu-text">Home One</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-two"}><span className="menu-text">Home Two</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-three"}><span className="menu-text">Home Three</span></NavLink></li>
                    </ul> */}
                </li>
                <li>
                    {/* <NavLink to={process.env.PUBLIC_URL + "/about"}><span className="menu-text">About Us</span></NavLink> */}
                    <a href={`#about`}><span className="menu-text" onClick={onClose}>About Us</span></a>
                </li>
                <li>
                    {/* <NavLink to={process.env.PUBLIC_URL + "/service"}><span className="menu-text">Services</span></NavLink> */}
                    <a href={`#services`}><span className="menu-text" onClick={onClose}>Services</span></a>
                </li>
                {/* <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/work"}><span className="menu-text">Work</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/work"}><span className="menu-text">Work</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/work-details/1`}><span className="menu-text">Work Details</span></NavLink></li>
                    </ul>
                </li> */}
                <li className="has-children">
                    {/* <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog</span></NavLink> */}
                    {/* <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span> */}
                    {/* <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog Grid</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-classic"}><span className="menu-text">Blog Classic</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li>
                    </ul> */}
                    <a href={`#careers`}><span className="menu-text" onClick={onClose}>Careers</span></a>

                </li>
                <li>
                <a href={`#contact`} className="menu-text" onClick={onClose} >Contact Us</a>
                    {/* <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink> */}
                </li>
            </ul>
        </nav>
            </div>
        </div>
    </div>
    )
}

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func
}

export default MobileMenu;
